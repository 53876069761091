@import "https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css"; 
@import "../node_modules/suneditor/dist/css/suneditor.min";
@import '../node_modules/suneditor/dist/css/suneditor.min.css';
.p-datatable table{
  table-layout: unset !important;
}
.reports .nav{
  display: block !important;
  width: 25%;
  margin-right: 5px;
  float: left;
  margin-bottom: 15px;
  //background: #f3f4f7;
  background: #fff;
}
.reports .tab-content{
  width: 73%;
  margin-left: 5px;
  float: left;
 // background-color: #EDEFF2;
 // padding: 50px 15px !important;
//  margin-bottom: 15px;
}
.reports .nav .nav-link{
 
  margin-bottom: 16px;
  background: #f3f4f7;
}

.nav-pills .nav-link.active{
  background-color: #fff;

}
.reports .nav-link{

  padding: 0.5rem;
}
.app-calendar{

  padding-bottom: 15px;
}
.fc .fc-toolbar-title{

  font-size: 0.95em !important;
}
.fc .fc-button{

  padding: 0.3em .5em !important;
}
.fc .fc-toolbar.fc-header-toolbar{
  margin-bottom: 0em !important;
}
.fc-view{
  margin-top: 5px;
}
.p-datatable .p-datatable-tbody > tr > td{

  padding: 5px 30px !important;
}

.p-inputswitch {
  display: inline-flex !important;
  vertical-align: middle !important;
  margin-right: 5px;
}

.myTable td:nth-child(1) {
  width:300px;
}
.p-datatable .p-datatable-tbody > tr{
   border-bottom: 1px dashed #b1b5b8;
}
.nav-profile .nav-pills{
  background: #AFB8BF;
}

 /* Search */
 .chat-search {
  height: 70px;
  display: table;
  width: 100%;
  .chat-search-box {
      display: table-cell;
      vertical-align: middle;
      position: relative;
  }
  .form-control {        
      padding: 0;
      box-shadow: none;
      background-color: #f1f3fa;
      border-color: #f1f3fa;;
      &::placeholder {
          color: darken(#b1b4c0, 10%);
      }
  }

  .btn {
      box-shadow: none !important;
      background-color: #f1f3fa;
      color: #b1b4c0;
  }
}

.user-status {
  position: absolute;
  height: 11px;
  width: 11px;
  background-color: #b1b4c0;
  border: 2.5px solid #fff;
  border-radius: 50%;
  bottom: 0;
  left: 35px;
}

.video-call{
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
  position: relative;    
  .video-call-head{
      img{
          height: 4rem;
          width: 4rem;
      }
  }
  .video-call-action{
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      padding: 60px 0 16px;
      background: linear-gradient(to top, #000 0, rgba(#000, 0) 90%);
  }
}

.voice-call{
  height: 450px;
  position: relative;
  .voice-call-head{
      img{
          width: 6rem;
          height: 6rem;
      }
  }
  .voice-call-action{
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
  }
}

.conversation-list {
  list-style: none;
  padding: 0 15px;
  li {
      margin-bottom: 20px;
      .conversation-actions {
          display: none;
      }
      &:hover{
          .conversation-actions {
              display: block;
          }
      }
              
  }
  .ctext-wrap {
      background: #f1f3fa;
      color: #000;
      border-radius: 3px;
      display: inline-block;
      padding: 7px 12px;
      position: relative;
      i {
          display: block;
          font-size: 12px;
          font-style: normal;
          font-weight: bold;
          position: relative;
      }
      p {
          margin: 0;
          padding: 0;
      }
      &:after {
          left: -11px;
          top: 0;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-width: 6px;
          margin-right: -1px;
      }
  }
  .conversation-text {
      float: left;
      font-size: 13px;
      margin-left: 12px;
      width: 80%;
  }
  .odd {
      .conversation-text {
          float: right !important;
          text-align: right !important;
          width: 70% !important;
      }
      .ctext-wrap {
          background-color: #fef5e4;
          color: #000;
          &:after {
              border-color: transparent;
              right: -10px;
              left: auto;
          }
      }
  }
}
.fs-12 {
  font-size: 12px !important;
}
.text-muted {
  color: #6c757d !important;
}
.float-end {
  float: right !important;
}
.w-100 {
  width: 100% !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.chat-search .form-control {
  padding: 0;
  box-shadow: none;
  background-color: #f1f3fa;
  border-color: #f1f3fa;
}
.chat-search .btn {
  box-shadow: none !important;
  background-color: #f1f3fa;
  color: #b1b4c0;
  border: none;
border-radius: unset;
}
.fs-14 {
  font-size: 14px !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
.text-dark {
  color: #343a40 !important;
}
.translate-middle {
  transform: translate(-50%,-50%) !important;
}
.start-50 {
  left: 50% !important;
}
.top-0 {
  top: 0 !important;
}
.position-absolute {
  position: absolute !important;
}
.frame {
  height: 345px;
  width: 90%;
  margin-top: 10px;
  overflow: auto;
}
@import '~dropzone/dist/min/dropzone.min.css';
.p-datatable .p-datatable-thead > tr > th {
  text-align: center;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #fff !important;
  background: #278cf1 !important;
  transition: box-shadow 0.2s;
}
.table th{
  color: #fff !important;
  background: #278cf1 !important;
}
.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #2196F3;
  border: 1px solid #2196F3;
  font-size: 0.8rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
  align-items: center;
}
.p-datepicker table td > span {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
}
.p-datepicker table td {
  padding: 0.4rem !important;
}
.dropup{
  z-index: 99999999999999999;
}
.fc-direction-ltr .fc-daygrid-more-link{
  color: #0360f2 !important;
}
.scrollbar-thumb{
  width: 13px !important;
}
.scrollbar-track-y{
  width: 14px !important;
}